@use 'base/variables' as v;
@use 'base/mixins' as m;

.nosotros {
    padding-top: 5rem;

    @include m.tablet {
        padding-top: 0;
        margin-top: -5rem;
    }

    .horizontal {
        color: transparent;
        width: 100%;
        border: 1px solid v.$violeta;
    }
    .nosotros-contenido {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include m.tablet {
            flex-direction: column;
        }

        .vertical {
            border: 1px solid v.$violeta;
            transform: scaleY(70);
            margin: 0;

            @include m.tablet {
                transform: scaleY(1);
                width: 60%;
            }
        }

        .texto-nosotros {
            width: 50%;

            @include m.tablet {
                width: 100%;
            }

            h2 {
                margin: 0;
                margin-bottom: 4rem;
                font-size: 4rem;
                text-align: center;
                font-weight: 100;
                color: #3F3B40;
                text-transform: uppercase;

                @include m.tablet {
                    font-size: 3rem;
                    margin-top: 3rem;
                }
        
                span {
                    color: v.$violeta;
                }
            }

            p {
                text-align: center;
                font-weight: 300;
                color: #3F3B40;
                padding-bottom: 5rem;
            }

            p {
                @include m.tablet {
                    width: 100%;
                }
            }
        }

        @include m.tablet {
            img {
                width: 100%;
            }
        }
    }
}

.quienes-somos {
    h2 {
        text-align: center;
        font-weight: 100;
        font-size: 4rem;
        color: v.$verdeAgua;
        text-transform: uppercase;

        @include m.tablet {
            font-size: 3rem;
        }
    }

    .horizontal {
        color: transparent;
        width: 100%;
        border: 1px solid v.$violeta;
    }
}

.quienes-contenido {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;

    @include m.tablet {
        flex-direction: column;
    }

    .vertical {
        border: 1px solid v.$violeta;
        transform: scaleY(70);
        margin: 0;

        @include m.tablet {
            display: none;
        }
    }
}

.quienes-textos {
    width: 40%;

    @include m.tablet {
        width: 80%;
    }
}

.qui-texto {
    position: relative;
    margin: 5rem 0;

    h3 {
        font-size: 2.4rem;
        font-weight: 100;
        color: #3F3B40;
    }

    p {
        color: #3F3B40;
    }
}

.circulo {
    position: absolute;
    top: 1.3rem;
    left: -1rem;
    background-color: v.$violeta;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    transform: scale(8);

    @include m.tablet {
        transform: scale(8);
        top: 1.3rem;
        left: -1rem;
    }
}