@use 'base/variables' as v;
@use 'base/mixins' as m;

.footer {
    margin-top: 5rem;
    width: 100%;
    background-color: v.$blanco;
    padding: 5rem 0 .5rem 0;

    p {
        text-align: center !important;
        color: #3F3B40;
        span {
            color: #3F3B40;
        }
    }
}

.footer-contenido {
    @include m.grid(3, 3rem);
    padding-bottom: 3rem;

    @include m.tablet {
        @include m.grid(1, 3rem);
    }

    .logo-social {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include m.tablet {
            grid-row: 3 / 4;
            flex-direction: row-reverse;
            justify-content: space-around;
            width: 100%;
        }

        .logo-footer {
            width: 20rem;

            @include m.tablet {
                width: 15rem;
            }
        }

        .social {
            img {
                width: 5rem;
            }
        }
    }

    nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        @include m.tablet {
            a {
                margin: 2rem 0;
            }
        }

        .contacto {
            color: v.$blanco;
            background-color: v.$violeta;
            padding: 1rem 2rem;
        }
    }

    .mail {
        display: flex;
        align-items: center;

        @include m.tablet {
            margin: 0 auto;
        }

        img {
            margin-right: .5rem;
        }
    }
}

.bg-violeta {
    margin-top: 5rem;
    background-color: v.$violeta;
    padding: 2.5rem;
}

.medio-pagos {
    background-color: v.$blanco;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem auto;

    h2 {
        font-size: 4rem;
        font-weight: 100;
        margin: 5rem 0;
        text-transform: uppercase;

        @include m.tablet {
            font-size: 3rem;
            text-align: center;
        }
    }
    
    img {
        width: 60%;
        margin-bottom: 5rem;

        @include m.tablet {
            width: 100%;
        }
    }
}