
// Colores

$negro : #0E0B16;
$blanco : #fff;
$violeta : #723087;
$verdeAgua: #78C5B1;
$celeste: #1B9AA1;
$azul: #144F89; 
// Tamaños para media querys

$telefono : 480px;
$tablet : 768px;
$escritorio : 998px;
$escritorioXL : 1200px;

// Fuentes

$fuente_principal : Helvetica, sans-serif;