@use 'base/variables' as v;
@use 'base/mixins' as m;

.bg-serv {
    background-color: #F8F5F9;
}

.servicios {
    width: 90%;
    max-width: 130rem;
    margin: 0 auto;
    margin-top: 7rem;
    text-align: center;

    @include m.tablet {
        width: 100%;
    }

    h2 {
        font-size: 4rem;
        font-weight: 100;
        text-transform: uppercase;

        @include m.tablet {
            font-size: 3rem;
        }

        span {
            color: v.$violeta
        }
    }

    p {
        font-size: 2rem;

        @include m.tablet {
            text-align: center !important;
        }
    }
}

.servicios-items {
    @include m.grid(4, 2rem);

    @include m.tablet {
        @include m.grid(1, 2rem);
        width: 80%;
        margin: 0 auto;
    }

    .iconos {
        width: 60px;
        height: 60px;
    }

    .icono-envio {
        object-fit: contain;
    }

    img {
        margin: 2rem;
    }

    h3 {
        margin: 0;
        font-weight: 100;
        font-size: 2rem;
        color: v.$violeta;
        text-transform: uppercase;
    }

    p {
        color: #3F3B40;
        font-size: 1.5rem;
        text-align: center !important;
    }
}

.item-imagen {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.09);
    width: 30rem;
    height: 33rem;
    border-radius: 1rem;

    &:hover {
        img {
            transform: scale(1.1);
        }
    }

    img {
        width: 19rem;
        height: 29rem;
        transition: transform .4s; 
    }
}

.icono-zapatillas {
    width: 150px;
    height: 150px;
}

.serv-det {
    margin: 0 auto;
    width: 90%;
    max-width: 130rem;
    padding-top: 5rem;

    @include m.tablet {
        width: 100%;
    }

    .titulo-serv-det {
        text-align: center;
        font-size: 4rem;
        font-weight: 100;
        color: v.$violeta;
        text-transform: uppercase;
    }
}

.servicios-detallados {
    margin: 10rem 0;
}

.servicio-detallado__item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 5rem 0;

    .acolchado {
        width: 35%;

        @include m.tablet {
            width: 90%;
        }
    }

    @include m.tablet {
        flex-direction: column-reverse;
        align-items: center;
    }

    img {
        width: 48%;

        @include m.tablet {
            width: 100%;
        }
    }

    .item-texto {
        width: 45%;
        text-align: left;

        @include m.tablet {
            width: 90%;
            text-align: center !important;
            margin-top: 3rem;
        }

        h2 {
            color: v.$verdeAgua;
            margin-top: 0;
            font-size: 4rem;
            font-weight: 100;
            text-transform: uppercase;

            @include m.tablet {
                font-size: 3rem;
            }
        }

        p {
            font-size: 1.5rem;
            color: #3F3B40;
        }
    }
}

.rev {
    flex-direction: row-reverse;

    @include m.tablet {
        flex-direction: column-reverse;
    }
}