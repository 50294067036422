@use 'base/variables' as v;
@use 'base/mixins' as m;

.bg-form {
    background-image: url('../img/Form.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 3rem;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-weight: 100;
        font-size: 4rem;
        color: v.$blanco;
        text-transform: uppercase;
    }

    form {
        @include m.grid(2, 3rem);
        width: 80%;

        @include m.tablet {
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        #direccion {
            grid-column: 1 / 2;
        }

        #consulta {
            grid-column: 1 / 3;

            @include m.tablet {
              width: 90%;
            }
        }

        input, textarea {
            margin: 2rem;
            padding: 1.5rem;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid v.$blanco;
            outline: none;
            color: v.$blanco;

            &::placeholder {
                font-size: 1.3rem;
                color: v.$blanco;
                text-transform: uppercase;
            }
        }

        textarea {
            width: 100%;
            height: 10rem;
        }

        .btn-enviar {
            grid-column: 1 / 2;
            border: none;
            background-color: v.$violeta;
            width: fit-content;
            padding: 1rem 4rem;
            cursor: pointer;

            @include m.tablet {
              padding: 2rem 4rem;
            }
        }
    }
}

.elementos {
    display: flex;
    align-items: center;
    margin: 2rem 0;
    width: 100%;
    margin-left: 2rem;
    margin-bottom: 0;

    p {
        margin-top: 2.5rem;
        font-size: 1.3rem;
        color: v.$blanco;
        text-transform: uppercase;
    }

    input {
        transform: scale(1.4);
        background-color: transparent;
    }
}

.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1px solid v.$blanco;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: rgba(204, 204, 204, 0.13);
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #002B4E;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    transform: rotate(35deg);
  }