@use 'base/variables' as v;

@mixin telefono {
    @media (max-width: v.$telefono) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: v.$tablet) {
        @content;
    }
}

@mixin escritorio {
    @media (max-width: v.$escritorio) {
        @content;
    }
}

@mixin escritorioXL {
    @media (max-width: v.$escritorioXL) {
        @content;
    }
}

@mixin grid($columnas, $separacion) {
    display: grid;
    grid-template-columns: repeat($columnas, 1fr);
    gap: $separacion;
}