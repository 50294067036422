@use 'base/variables' as v;

html, body {
    max-width: 100% !important;
    overflow-x: hidden !important;
}

html {
    font-size: 62.5%;
    box-sizing: border-box;

    * {
        letter-spacing: 1.4px;
        color: #000;
        font-family: v.$fuente_principal;
    }
}

body {
    font-size: 16px;
}

*,*:before,*:after {
    box-sizing: inherit;
}

.contenedor {
    width: 95%;
    max-width: 113rem;
    margin: 0 auto;
}

a {
    text-decoration: none;
}

img{
    width: auto;
	margin: 0;
    object-fit: contain !important;
}

picture {
	margin: 0;
}

p {
    line-height: 2.3rem;
    font-size: 1.3rem;
    font-weight: 300;
}

button {
    font-size: 1.3rem;
    width: max-content;
    padding: 1.8rem 3.6rem;
    border-radius: .5rem;
    box-shadow: none;
    border: none;
    cursor: pointer;
    font-weight: 600;
}