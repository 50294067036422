@use 'base/variables' as v;
@use 'base/mixins' as m;

.donde-estamos {
    margin-top: 6rem;
    margin-bottom: 6rem;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #EADFED;
    
    h2 {
        font-size: 4rem;
        font-weight: 100;
        text-transform: uppercase;

        @include m.tablet {
            text-align: center;
        }

        span {
            color: v.$violeta;
        }
    }

    p {
        font-size: 2rem;
        color: #3F3B40;

        @include m.tablet {
            text-align: center;
        }
    }
}

.mapas {
    @include m.grid(3, 2rem);

    @include m.tablet {
        @include m.grid(1, .5rem);
    }
}

.mapa {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 5rem 0;
    padding: 1rem;

    iframe {
        width: 100%;
        max-width: 342px;
        height: 257px;
    }

    @include m.tablet {
        flex-direction: column-reverse;

        iframe {
            width: 100%;
        }
    }

    .mapa-texto {
        h3 {
            color: #3F3B40;
            text-align: center;
            font-size: 3rem;
            font-weight: 100;
            text-transform: uppercase;
        }

        p {
            text-align: center;
            font-size: 2rem;
            color: #3F3B40;
        }
    
        a {
            width: fit-content;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            transition: all, .4s;
            padding: 0 1rem;
            background-color: v.$violeta;
            margin: 0 auto;
    
            img {
                width: 2.5rem;
                margin-left: 1rem;
            }
    
            p {
                color: v.$blanco;
                font-size: 2rem;
            }
        }
    }
}