@use 'base/variables' as v;
@use 'base/mixins' as m;

.nav-bg {
    background-color: v.$blanco;
    position: fixed;
    z-index: 20;
    width: 100%;
    border-bottom: 1px solid v.$violeta;

    .navegador {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include m.tablet {
            position: fixed;
            width: 100%;
            height: 9rem;
            justify-content: flex-end;
            background-color: v.$blanco !important;
        }

        .logo-movil {
            display: none;

            @include m.tablet {
                display: block;
                position: absolute;
                top: 0;
                left: 1rem;
            }
        }

        .logo {
            margin: .5rem;
            border: none;
            transition: transform, .4s;
            transform: scale(.8);

            @include m.tablet {
                display: none;
            }
        }

        picture {
            img, source {
                width: 20rem;
                border: none;
            }
        }
    }
}

.btn {
    display: none !important;

    @include m.tablet {
        display: inline-block !important;
        position: fixed;
        top: 1rem;
        right: 1rem;
        background-color: transparent;
        padding: .5rem 1rem;
    }
}

.btn-x {
    display: none;

    @include m.tablet {
        display: inline-block;
        position: absolute;
        top: 1rem;
        right: 2rem;
        padding: 0;
        background-color: transparent;
        color: v.$violeta;
        font-size: 4rem;
        font-weight: 100;
    }
}

.nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include m.tablet {
        display: none;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        position: fixed;
        top: 0;
        right: 0;
        background-color: v.$blanco;
        width: 50%;
        height: 100%;
        padding-top: 20%;
        padding-bottom: 40%;
        padding-left: 10%;
    }

    a {
        font-size: 1.6rem;
        font-weight: 300;

        &:hover {
            transition: all, .4s;
            color: v.$violeta;
        }

        @include m.tablet {
            color: v.$violeta;

            &:hover {
                transition: all, .4s;
                color: v.$blanco
            }
        }
    }

    .contacto {
        color: v.$blanco;
        background-color: v.$violeta;
        padding: 1rem 2rem;

        &:hover {
            color: v.$blanco;
            background-color: v.$azul;
        }

        @include m.tablet {
            padding: 0;
            color: v.$violeta;
            background-color: transparent;
            &:hover {
                background-color: transparent;
            }
        }
    }
}

.header {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;

    @include m.tablet {
        height: 112vh;
    }

    video {
        width: 100%;

        @include m.tablet {
            margin-top: 8rem;
        }
    }

    @include m.tablet {
        max-height: 70rem;
        min-height: 50rem;
    }

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color:rgba(255, 255, 255, 0.8);

        @include m.tablet {
            padding-top: 60%;
            height: fit-content;
        }
    }
}

.contenido-video {

    @include m.tablet {
        margin-top: 4rem;
        border: 1px solid rgb(231, 231, 231);
        background-color: v.$blanco;
        box-shadow: 0px 14px 19px 17px rgba(0,0,0,0.07);
        -webkit-box-shadow: 0px 14px 19px 17px rgba(0,0,0,0.07);
        -moz-box-shadow: 0px 14px 19px 17px rgba(0,0,0,0.07);
        padding-left: 1.5rem;
    }

    .hashtags {
        p {
            margin-right: 1rem;
            font-size: 3.5rem;
            color: v.$violeta;

            @include m.tablet {
                font-size: 2rem;
            }
        }

        @include m.tablet {
            margin: 0;
        }
    }

    h1 {
        font-size: 7rem;
        width: 90%;
        max-width: 50rem;
        font-weight: 500;
        margin: 0;

        @include m.tablet {
            font-size: 4rem;
        }

        span {
            color: v.$violeta;
        }
    }

    p {
        font-size: 2rem;
    }
}

.desde {
    color: v.$violeta;
    font-size: 3rem;
    font-style: italic;
}